<template>
    <div class="login-test">
      
    <div class="">
        <div class="background-img ">
         <!--<div class="" style="background-image: url('img/pattern.svg');width: 30%;height: 100vh;position: absolute;top: 0;left: 0;"></div>--> 
         <div class="" style="background:black;width:100%;height: 100vh;position: absolute;top: 0;left: 0;opacity: 0.3;"></div>
         <div class="signin-form content-area" style="position: relative;">
          <div class="container d-flex justify-content-end" >
          
          <div class="p-4 col-lg-5 col-12" style="background-color: #ffffff00;border-radius: 20px;">
           
            <div class="px-2 col-12 d-flex justify-content-center" >
              <img src="img/geotech-logo1.png" alt="" style="width: 90%;" >
            </div>
            
            <!--<div class="text-center" style="font-weight: 700;font-size: 20px;">Ιερά Μονή Στροφάδων & Αγίου Διονυσίου - Σύστημα Διαχείρισης Ακίνητης Περιουσίας (ΣυΔΑΠ)</div>
            -->
            <div class="col-12 text-light">
              <form @submit.prevent="onSubmit" class="text-black " style="width:100%">
                <h3 class="text-center pt-5">Σύνδεση</h3>
        
                <div class="form-group">
                  <i class="fa fa-user icon text-primary"></i>
                  <input  type="text"
                          id="email"
                          placeholder="username"
                          v-model="username"  class="form-control form-control-lg rounded-pill" style="background-color: rgba(0, 0, 0, 0)!important;" />
                </div>
        
                <div class="form-group mt-4">
                  <i class="fa-solid fa-lock text-primary"></i>
                
                  <input type="password"
                        id="password"
                        placeholder="password"
                        v-model="password" class="form-control form-control-lg rounded-pill" style="background-color: rgba(0, 0, 0, 0)!important;" />
                </div>
                
        
              <div class="d-grid gap-2">
                <button type="submit" class="btn btn-outline-primary btn-lg mt-5 btn-block rounded-pill" style="color:weight; border: 2px solid weight;">Σύνδεση</button>
              </div>
            </form>
          </div>
          </div>
      
         
      </div>
        </div>
        <!--<div style="position: absolute;bottom: 0;left: 0;width: 100%;">
          <div class="d-flex justify-content-end align-items-center">
            powered by <a href="https://www.lesd.gr/" target="_blank"> <img src="img/SYSTEM-DYNAMICS-logo-3.png" class="p-2" style="width: 100px;"></a> 
        </div>
        </div>-->
        </div>
        
    </div>
    
    </div>
    </template>
    
    <script>
      //import store from "../../store";
      //import routes from '../router/index.js'
      //import axios from 'axios'

      export default {
        data () {
          return {
            username: '',
            password: '',
            selected_languege:'gr'
          }
        },
        methods: {
          onSubmit () {
            const formData = {
              username: this.username,
              password: this.password,
            }
            //console.log(formData)
            //localStorage.setItem('username', this.username)
            //this.$store.dispatch('login', {username: formData.username, password: formData.password})
    
              this.$store.dispatch('login', {username: formData.username, password: formData.password})
              //routes.push({ path: '/dashboard' }
            /*
            if (store.getters.isAuthenticated) {
              console.log('heredashboard')
              this.$router.push('dashboard')
            } else {
              console.log('heresignin')
              console.log(store.getters.isAuthenticated)
              this.$router.push('signin')
            }
            */
          },
          changeLang(){
            this.$store.commit('set', ['languege', this.selected_languege])
    
          },
          /*
          wordpressLogin(){
            const loginData = {
                username: 'dvallianatos',
                password: 'g3(6Orya4rjU0nZhO$'
            };

            axios.post('https://enact.idcom.gr/cms/wp-json/custom/v1/login', loginData, {
                headers: {
                    'Content-Type': 'application/json'
                },
                withCredentials: true // Allow including cookies in the request
            })
            .then(response => {
                const cookies = response.headers['set-cookie'];
                console.log('Received cookies:', cookies);
                console.log('Response Data:', response.data);
            })
            .catch(error => {
                console.error('Error:', error);
            });
          },
          checkUserLoginStatus() {
            axios.get('https://enact.idcom.gr/cms/wp-json/custom/v1/check_user_login',{withCredentials: true})
              .then(response => {
                const data = response.data;
                console.log(data)
                if (data === 'loggedin') {
                  console.log('User is logged in');
                  // Perform actions for logged-in users
                } else {
                  console.log('User is logged out');
                  // Perform actions for logged-out users
                }
              })
              .catch(error => {
                console.error('Error checking user login status:', error);
              });
          },
          checkCookie(name) {
              // Split the cookies string into individual cookies
              const cookies = document.cookie.split(';');

              // Loop through the cookies to find the one with the specified name
              for (let i = 0; i < cookies.length; i++) {
                const cookie = cookies[i].trim();

                // Check if this cookie has the specified name
                if (cookie.startsWith(name + '=')) {
                  // Cookie found
                  return true;
                }
              }

              // Cookie not found
              return false;
            }

          */
        },
        created(){
          this.$store.commit('set', ['languege', 'gr'])
          //this.wordpressLogin()
          //setTimeout(()=>{this.checkUserLoginStatus()},3000)
          
          /*const cookieName = 'wordpress_test_cookie';
          const cookieExists = this.checkCookie(cookieName);
          console.log(cookieExists)
          if (cookieExists) {
              this.checkUserLoginStatus()
          } else {
              this.wordpressLogin()
          }*/
        }
      }
    </script>
    
    <style >
    .form-group{
      position: relative;
    }
    .form-group i{
      position: absolute;
      top: 15px;
      right: 20px;
      color: #23211d;
    }
    .button:hover {
      color: white;
    }
    
      .signin-form {
    
        
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 100vh;
      
      }
    
    
    .hpanel {
        position: absolute;
        top: 0;
        bottom: 0;
        height: 100%;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        color: #fff
    }
    
    .rightpan {
        
        width: 40%;
     
    }
  
    .background-img {
      /*background: linear-gradient(-135deg, #002865, #000e23);*/
      background-image: url('../../public/img/hex-textured-background-networking.jpg');
      background-size: cover;
      background-repeat: no-repeat;
    }
   
    
    
    
    
    body{
    
        font-family: 'Manrope', sans-serif;
       
    }
    
    .noscroll {
        overflow: hidden
    }
    @media only screen and (max-width: 1092px) {
      .rightpan {
            right: 0%;
        width: 100%;
        -webkit-transform: skew(0deg);
        -moz-transform: skew(0deg);
        -o-transform: skew(0deg);
    }
    .rightpan .content-area {-webkit-transform: skew(0deg);
        -moz-transform: skew(0deg);
        -o-transform: skew(0deg);
      }
    .rightpan .background-img {
        background:rgba(0, 0, 0, 0.7)
    }
    
    }
    
    </style>
    